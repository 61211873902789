import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useUserStore } from '@stores/UserStore';
import { dateTimeReviver } from './dateTimeReviver';

export const contentApi = axios.create({
    baseURL: '/',
});

export type useFetchProps = {
    config?: AxiosRequestConfig;
};

export type useAuthAxiosResponse = {
    axiosInstance: AxiosInstance;
};

const jsonInterceptor = [
    (response: AxiosResponse<unknown>) => {
        const contentType = response.headers['content-type'];
        if (contentType === undefined) return response;
        if (!/json/gi.test(contentType)) {
            /** not json, like binary string */
            return response;
        }
        if (response?.data) {
            const str = JSON.stringify(response.data);
            response.data = JSON.parse(str, dateTimeReviver);
            return response;
        } else {
            return response;
        }
    },
    (error: unknown) => Promise.reject(error),
];

const useAuthAxios = (): useAuthAxiosResponse => {
    // eslint-disable-next-line prefer-const

    const [, setAxiosInstance] = useState<AxiosInstance>();
    const [initialized] = useState(false);

    useUserStore.subscribe(
        (state) => state.user,
        (user) => console.log(`user Subscribe ${user}`),
    );

    useEffect(() => {
        const instance = axios.create({
            baseURL: '/',
        });
        setAxiosInstance(instance);
    }, [initialized]);

    contentApi.interceptors.response.use(...jsonInterceptor);
    return { axiosInstance: contentApi };
};

export { useAuthAxios };
