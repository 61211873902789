import { MouseEvent } from 'react';

export interface LogoutButtonProps {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    title: string;
}

export function LogoutButton(props: LogoutButtonProps): JSX.Element {
    const { title, onClick } = props;
    return (
        <div>
            <button
                type="button"
                className="ml-3 btn btn-link tw-text-white tw-font-bold hover:tw-text-white"
                onClick={onClick}
                title={title}
            >
                Log out
            </button>
        </div>
    );
}
