import { useOidc } from '@axa-fr/react-oidc';
import { useEffect } from 'react';

const Logout = (): JSX.Element => {
    const { logout } = useOidc();

    useEffect(() => {
        (async () => {
            console.debug('Logout: Logging account out');
            await logout('/auth/signout-callback-oidc');
        })();
    }, [logout]);

    return (
        <div className="tw-grid tw-mt-10 tw-justify-items-center">
            <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                <div className="tw-max-w-lg tw-mx-auto tw-overflow-hidden tw-rounded-lg tw-shadow-lg tw-bg-gray-50">
                    <div className="tw-py-5 tw-px-5 tw-text-center">
                        <h2 className="tw-text-4xl tw-font-semibold">Log out</h2>
                        <p className="tw-mt-8 tw-mb-8">Please wait while we log your account out.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Logout };
