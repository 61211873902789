import {
    createMongoAbility,
    MongoAbility,
    AbilityBuilder,
    Ability,
    AbilityClass,
    SubjectRawRule,
    ExtractSubjectType,
    MongoQuery,
} from '@casl/ability';
import { AnyObject } from '@casl/ability/dist/types/types';
import { Organisation } from '@stores/types/OrgModel';
import { Registration } from '@stores/types/Registration';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'upload_documents' | 'users_manage';
export type Subjects = 'organisation' | 'registration' | 'all' | Registration;

//export type AppAbilityType = Ability<[Actions, Subjects]>;

export type AppAbilityType = MongoAbility<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

//export const AppAbility = createMongoAbility;

export default function defineRulesFor(
    organisation: Organisation | null,
): SubjectRawRule<Actions, ExtractSubjectType<Subjects>, MongoQuery<AnyObject>>[] {
    const { can, rules } = new AbilityBuilder(AppAbility);

    if (organisation == null) return rules;

    for (const permission of organisation.permissions) {
        if (permission.allowed && permission.action === 'admin') {
            console.debug('organisation admin');
            //TODO: Populate permissions from server model.
            can('upload_documents', 'registration');
            can('users_manage', 'organisation');
        }
    }

    //enumerate registrations
    for (const registration of organisation.registrations) {
        for (const permission of registration.permissions) {
            if (permission.allowed) {
                const action = permission.action as Actions;
                can(action, 'registration', { id: registration.id });
            }
        }
    }

    return rules;
}

export function buildAbilityFor(organisation: Organisation | null): AppAbilityType {
    return createMongoAbility(defineRulesFor(organisation), {
        // https://casl.js.org/v5/en/guide/subject-type-detection
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        detectSubjectType: (object) => object!.modelName,
    });
}
