import { useQuery, UseQueryResult } from 'react-query';
import { useAuthAxios } from '@services/RegistrationRepository/useAuthAxios';
import { useOrgStore } from '@stores/OrgStore';
import { Organisation } from '@stores/types/OrgModel';
import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { shallow } from 'zustand/shallow';
import { useGetConfig } from '@config/configuration';

type OrganisationList = {
    organisations: Organisation[];
};

const useOrgData = (): UseQueryResult => {
    const { oidcUser } = useOidcUser();
    const { isAuthenticated } = useOidc();
    const appConfig = useGetConfig();
    const apiRootUrl = appConfig.apiRootUrl;
    const { setOrgDataLoaded, setOrganisations } = useOrgStore(
        (state) => ({
            setOrgDataLoaded: state.setOrgDataLoaded,
            setOrganisations: state.setOrganisations,
        }),
        shallow,
    );

    const { axiosInstance: axiosClient } = useAuthAxios();

    return useQuery<OrganisationList>(
        'OrgData',
        async () => {
            const { data } = await axiosClient.get<OrganisationList>(`${apiRootUrl}org`);
            setOrganisations(data.organisations);
            setOrgDataLoaded();
            return data;
        },
        {
            enabled: !!oidcUser && isAuthenticated,
            staleTime: 30 * 60 * 1000, // 30 minutes
            onError: (error) => {
                console.error('Failed to fetch organisation data:', error);
            },
        },
    );
};

export { useOrgData };
