import React from 'react';

type ExternalLinkProps = {
    href: string;
    children?: React.ReactNode;
};

const ExternalLink = (props: ExternalLinkProps): JSX.Element => (
    <li className="list-inline-item">
        <a href={props.href} target="_blank" rel="noopener noreferrer" className="qg-legal-link">
            {props.children}
        </a>
    </li>
);

export const AppFooter = (): JSX.Element => {
    const year = new Date().getFullYear();

    return (
        <footer className="mt-auto">
            <div className="qg-legal row">
                <ul className="list-inline d-print-none">
                    <ExternalLink href="https://www.qld.gov.au/legal/copyright/">Copyright</ExternalLink>
                    <ExternalLink href="https://www.housing.qld.gov.au/global/disclaimer">Disclaimer</ExternalLink>
                    <ExternalLink href="https://www.qld.gov.au/legal/privacy/">Privacy</ExternalLink>
                    <ExternalLink href="https://www.qld.gov.au/right-to-information/">
                        Right to information
                    </ExternalLink>
                    <ExternalLink href="https://www.qld.gov.au/help/accessibility/">Accessibility</ExternalLink>
                    <ExternalLink href="https://www.smartjobs.qld.gov.au/">Jobs in Queensland Government</ExternalLink>
                    <ExternalLink href="https://www.qld.gov.au/languages/">Other languages</ExternalLink>
                </ul>
                <p className="qg-copyright">
                    &copy; The State of Queensland <span id="qg-copyright-owner">(Department of Housing)</span>
                    <span id="qg-copyright-daterange"> 2009-{year}</span>
                </p>
                <p>
                    <a
                        href="https://www.qld.gov.au/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="qg-legal-link"
                    >
                        Queensland Government
                    </a>
                </p>
            </div>
        </footer>
    );
};
