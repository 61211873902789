import imageCoatOfArms from '@assets/images/qg-coa-mono-rev.png';
import imageCoatOfArmsStacked from '@assets/images/Qld-CoA-Stylised-2LsS-mono-rev-min-size.png';

export function CoatOfArmsImage(): JSX.Element {
    const alt = 'Queensland Government';
    return (
        <>
            <img
                src={imageCoatOfArms}
                height={50}
                width={287}
                alt={alt}
                className="d-sm-none d-none d-md-block d-print-none"
            />
            <img src={imageCoatOfArmsStacked} alt={alt} className="d-md-none d-lg-none d-xl-none d-print-none" />
        </>
    );
}
