import { useOrgData } from 'hooks/useOrgData';
import { useEffect } from 'react';
import { useOrgStore } from '@stores/OrgStore';
import { shallow } from 'zustand/shallow';

// Wrapper around Loading Organisation Data
export const OrgDataLoader: React.FC = () => {
    const { isOrgDataLoading, setIsOrgDataLoading } = useOrgStore(
        (state) => ({
            isOrgDataLoading: state.isOrgDataLoading,
            setIsOrgDataLoading: state.setIsOrgDataLoading,
        }),
        shallow,
    );
    const { isLoading } = useOrgData();

    useEffect(() => {
        if (isLoading !== isOrgDataLoading) {
            setIsOrgDataLoading(isLoading);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isOrgDataLoading]);

    return null;
};
