import { useOidc } from '@axa-fr/react-oidc';
import { useState } from 'react';
import loginPortalImg from 'assets/images/login-portal.jpg';
import { LoadingButton } from './LoadingButton';

export const SignInCard: React.FC = () => {
    const { login } = useOidc();
    const [isLoading, setIsLoading] = useState(false);

    const handleLoginClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await login();
        } catch (error) {
            console.error('Login failed:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
            <div className="tw-max-w-lg tw-mx-auto tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg ">
                <img className="tw-object-cover tw-w-full tw-h-56" src={loginPortalImg} alt="" />
                <div className="tw-py-5 tw-px-5 tw-text-center">
                    <h2 className="tw-text-4xl tw-font-semibold">Sign in to your account</h2>
                    <p className="tw-mt-8 tw-mb-8">
                        Access to this site requires a <strong>Qld Digital Identity</strong> account.
                    </p>
                    <LoadingButton
                        text="Go to Qld Digital Identity"
                        isLoading={isLoading}
                        onClick={handleLoginClick}
                    ></LoadingButton>
                </div>
            </div>
        </div>
    );
};
