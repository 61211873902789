import { ReactNode } from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ExternalLinkProps = {
    href: string;
    children: ReactNode;
    className?: string;
};

export const ExternalLink = (props: ExternalLinkProps): JSX.Element => {
    const { href, children, className } = props;

    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer noopener"
            className={className}
            title="This link opens to an external link not controlled by this website"
        >
            {children}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="tw-ml-2" />
        </a>
    );
};
