import { useQuery, UseQueryResult } from 'react-query';
import { useAuthAxios } from '@services/RegistrationRepository/useAuthAxios';
import { Notification } from './Notification';
import { useGetConfig } from '@config/configuration';

export type NotificationList = {
    notifications: Notification[];
};

const useNotificationsData = (): UseQueryResult<NotificationList> => {
    const appConfig = useGetConfig();
    const apiRootUrl = appConfig.apiRootUrl;
    const { axiosInstance: axiosClient } = useAuthAxios();

    return useQuery(
        'NotificationsData',
        () =>
            axiosClient.get<NotificationList>(`${apiRootUrl}communiques`).then((res) => {
                return res.data;
            }),
        { enabled: true, cacheTime: Infinity },
    );
};

export { useNotificationsData };
