/**
 * Adds a trailing slash to a URL if it is missing.
 *
 * @param url - The URL to be processed.
 * @returns The URL with a trailing slash if it was missing.
 */
const addTrailingSlashIfMissing = (url: string): string => {
    if (url.length === 0) {
        return '/';
    }
    return url.endsWith('/') ? url : `${url}/`;
};

export { addTrailingSlashIfMissing };
