import { Skeleton } from 'primereact/skeleton';

const HomeCardSkeleton = (): JSX.Element => {
    return (
        <div className="tw-max-w-md tw-overflow-hidden tw-shadow-lg tw-border-yellow-600 tw-border-t-4 tw-border-solid tw-border-b-0 tw-border-l-0 tw-border-r-0 hover:tw-shadow-lg tw-cursor-pointer hover:tw-bg-gray-100 hover:tw-underline">
            <div className="tw-flex tw-justify-center tw-mt-4">
                <Skeleton size="120px" shape="circle"></Skeleton>
            </div>
            <div className="tw-px-6 tw-py-4">
                <h2 className="tw-font-bold tw-text-xl tw-mb-2">Loading profile, please wait...</h2>
                <Skeleton width="20em" height="3em"></Skeleton>
            </div>
        </div>
    );
};

export { HomeCardSkeleton };
