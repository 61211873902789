// ISO8601 yyyy-mm-ddThh:mm:ss.ffffffZ
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.*\d*Z$/;

/**
 * Revive json, parsing ISO8601 dates into Date primitives where possible.
 * @param key
 * @param value
 * @returns js object
 */
export const dateTimeReviver = (key: unknown, value: unknown): unknown | Date => {
    if (typeof value === 'string' && dateFormat.test(value)) {
        return new Date(value);
    }
    return value;
};
