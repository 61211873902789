import { Helmet } from 'react-helmet-async';
import imageCoatOfArms from '@assets/images/coa-thumbnail.png';

const HeaderHelmet = (): JSX.Element => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <link rel="icon" href="/favicon.ico" />

            <link
                href="//fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic&display=swap"
                rel="stylesheet"
                type="text/css"
            />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="title" content="Regulatory self-service portal" />
            <meta name="description" content="Regulatory self-service portal." />
            <meta name="keywords" content="Regulatory self-service portal" />
            {/* <!-- Google / Search Engine Tags --> */}
            <meta property="og:locale" content="en_AU" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Regulatory self-service portal." />
            <meta property="og:title" content="Compare retirement villages" />
            <meta property="og:image" content={imageCoatOfArms} />
        </Helmet>
    );
};

export { HeaderHelmet as AppHelmet };
