export function HeaderAccessNavigation(): JSX.Element {
    return (
        <section id="qg-access" role="navigation" aria-labelledby="landmark-label">
            <h2 id="landmark-label" tabIndex={0}>
                Skip links and keyboard navigation
            </h2>
            <ul>
                <li id="access-instructions">
                    <a href="https://www.qld.gov.au/help/keyboard#section-aria-keyboard-navigation">
                        Use tab and cursor keys to move around the page (more information)
                    </a>
                </li>
            </ul>
        </section>
    );
}
