import { Helmet } from 'react-helmet-async';

export function PageHelmet(): JSX.Element {
    return (
        <Helmet>
            <title>Regulatory self-service centre</title>
            <meta name="description" content="Regulatory self-service centre" />
            <meta name="keywords" content="Regulatory self-service centre" />
            <meta itemProp="description" content="Regulatory self-service centre" />
        </Helmet>
    );
}
