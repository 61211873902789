/* eslint-disable @typescript-eslint/no-unused-vars */
import { OidcUser } from '@axa-fr/react-oidc/dist/User';
import { createWithEqualityFn } from 'zustand/traditional';
import { createJSONStorage, persist, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

export type UserState = null | 'created' | 'active' | 'inactive';

export type UserStoreModel = {
    user: OidcUser | null;
    version: number;
    userState: UserState | null;
    setUser(user: OidcUser | null): void;
    setUserState(userState: UserState | null): void;
    incrementVersion(): void;
};

const useUserStore = createWithEqualityFn<UserStoreModel>()(
    subscribeWithSelector(
        persist(
            (set, get) => ({
                user: null,
                version: 1,
                userState: null,
                setUser: (user: OidcUser | null): void => {
                    set((_state) => ({ user: user, version: _state.version + 1 }));
                },
                incrementVersion: (): void => set((state) => ({ version: state.version + 1 })),
                setUserState: (userState: UserState | null): void => set((_state) => ({ userState: userState })),
            }),
            {
                name: 'user-storage',
                storage: createJSONStorage(() => window.localStorage),
            },
        ),
    ),
    shallow,
);

export { useUserStore };
