import { MouseEvent } from 'react';
import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { useOrgStore } from '@stores/OrgStore';
import { useNavigate } from 'react-router-dom';
import { routes } from '@pages/routes';
import { shallow } from 'zustand/shallow';
import { LogoutButton } from './LogoutButton';

export const LogoutContainer: React.FC = () => {
    const clearOrgStore = useOrgStore((state) => state.clear, shallow);
    const navigate = useNavigate();
    const { oidcUser } = useOidcUser();
    const { isAuthenticated } = useOidc();

    const handleLogoutClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        clearOrgStore();
        navigate(routes.LOGOUT);
    };

    if (!oidcUser && !isAuthenticated) {
        return null;
    }

    return (
        <li>
            <LogoutButton onClick={handleLogoutClick} title={`Log out ${oidcUser?.email}`} />
        </li>
    );
};
