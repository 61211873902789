import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { AppHelmet } from '@components/AppHelmet/AppHelmet';
import { queryClient as appQueryClient } from '@services/queryClient';

import InnerApp from 'InnerApp';

// eslint-disable-next-line no-empty-pattern
function App(): JSX.Element {
    return (
        <QueryClientProvider client={appQueryClient}>
            <HelmetProvider>
                <AppHelmet />
                <InnerApp />
            </HelmetProvider>
        </QueryClientProvider>
    );
}

export default App;
