import React, { Suspense } from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import './styles/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from '@components/Fallback/Fallback';

const renderApp = (rootElement: HTMLElement) => {
    const root = createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<div>Loading...</div>}>
                    <App />
                </Suspense>
            </ErrorBoundary>
        </React.StrictMode>,
    );
};

const rootContainer = document.getElementById('root');

if (typeof window !== 'undefined' && rootContainer) {
    renderApp(rootContainer);
} else {
    throw new Error('Unable to locate root element with id "root"');
}
