import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    isLoading?: boolean;
    loadingText?: string;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
    text,
    isLoading = false,
    loadingText = 'Please wait...',
    className = '',
    onClick,
    ...rest
}) => (
    <button className={`btn btn-success ${className}`} onClick={onClick} disabled={isLoading} {...rest}>
        {isLoading ? (
            <>
                <span>{loadingText}</span>
                <FontAwesomeIcon className="tw-ml-2" icon={faSpinner} spin />
            </>
        ) : (
            text
        )}
    </button>
);
