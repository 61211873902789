import { useOidcUser } from '@axa-fr/react-oidc';
import { useOrgStore } from '@stores/OrgStore';
import { OrganisationSelector } from '@components/OrganisationSelector/OrganisationSelector';
import { shallow } from 'zustand/shallow';
import { useEffect, useMemo } from 'react';
import { Organisation } from '@stores/types/OrgModel';

const OrganisationContainer: React.FC = () => {
    const { organisations, setSelectedOrganisation, setSelectedRegistration } = useOrgStore(
        (state) => ({
            organisations: state.organisations,
            setSelectedOrganisation: state.setSelectedOrganisation,
            setSelectedRegistration: state.setSelectedRegistration,
        }),
        shallow,
    );
    const { oidcUser } = useOidcUser();

    const setSelected = (orgId: number) => {
        const selOrg = organisations.find((org) => org.id === orgId);
        if (selOrg) {
            setSelectedOrganisation(selOrg);
            setSelectedRegistration(selOrg?.registrations[0]);
        }
    };

    useEffect(() => {
        if (organisations.length === 1) {
            const orgId = organisations[0].id;
            console.debug('Single Organisation with id', orgId);
            setSelected(orgId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations]);

    const showContainer = useMemo(
        () => oidcUser && organisations && organisations.length > 0,
        [oidcUser, organisations],
    );

    const singleOrganisation = useMemo(() => organisations && organisations.length === 1, [organisations]);

    if (!showContainer) return null;

    return (
        <>
            {singleOrganisation ? (
                <SingleOrganisationView organisation={organisations[0]} />
            ) : (
                <MultipleOrganisationsView organisations={organisations} />
            )}
        </>
    );
};

const SingleOrganisationView: React.FC<{ organisation: Organisation }> = ({ organisation }) => (
    <>
        <label className="tw-font-bold">Organisation:</label> {organisation.name}
    </>
);

const MultipleOrganisationsView: React.FC<{ organisations: Organisation[] }> = ({ organisations }) => (
    <div className="tw-w-full tw-mb-2">
        Select to change your organisation:
        <div className="md:tw-w-1/2">
            <OrganisationSelector organisations={organisations} />
        </div>
    </div>
);

export { OrganisationContainer };
