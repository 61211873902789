import { ExternalLinks } from '@pages/externalLinks';
import { Link } from 'react-router-dom';
import { routes } from '@pages/routes';
import { ExternalLink } from '@components/ExternalLink';
import { LogoutContainer } from './LogoutContainer';
import { CoatOfArmsImage } from './CoatOfArmsImage';

type SiteHeaderProps = {
    withAuth?: boolean;
};

const SiteHeader = ({ withAuth = true }: SiteHeaderProps): JSX.Element => {
    return (
        <header id="qg-site-header" className="d-flex flex-row justify-content-between">
            <div className="tw-w-full tw-flex tw-flex-none">
                <div className="qg-coat-of-arms tw-flex">
                    <a href="//www.qld.gov.au" tabIndex={0} target="_blank" rel="noreferrer noopener">
                        <CoatOfArmsImage />
                    </a>
                </div>
                <div className="tw-flex-1 tw-justify-end">
                    <ul className="qg-utilities list-inline mr-3 tw-flex tw-justify-end">
                        {withAuth ? (
                            <>
                                <li>
                                    <ExternalLink
                                        href={ExternalLinks.ContactUs}
                                        className="tw-text-white tw-font-bold hover:tw-text-white"
                                    >
                                        Contact Us
                                    </ExternalLink>
                                </li>
                                <LogoutContainer />
                            </>
                        ) : null}
                    </ul>
                </div>
            </div>
            <div className="mt-1 mb-2 tw-flex tw-w-full tw-items-baseline">
                <div className="tw-flex-auto">
                    <h1 className="site-heading">
                        {withAuth ? (
                            <Link to={routes.HOME} className="site-heading__link">
                                Regulatory self-service centre
                            </Link>
                        ) : (
                            // No access to router in some instances of unauth, so can't use Link
                            <a href={routes.HOME} className="site-heading__link">
                                Regulatory self-service centre
                            </a>
                        )}
                    </h1>
                </div>
            </div>
        </header>
    );
};

export { SiteHeader, type SiteHeaderProps };
