import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { routes } from 'pages/routes';
import { useOidcUser } from '@axa-fr/react-oidc';
import { useNotificationsData } from 'hooks/useNotificationsData';
import 'react-popper-tooltip/dist/styles.css';

import { formatAsDefaultDate } from '@utils/dateFormatter';

const PageHelmet = (): JSX.Element => {
    return (
        <Helmet>
            <title>Regulatory self-service centre</title>
            <meta name="description" content="Regulatory self-service centre." />
            <meta name="keywords" content="Regulatory self-service centre" />
            <meta itemProp="description" content="Regulatory self-service centre" />
        </Helmet>
    );
};

const NotificationPage = (): JSX.Element => {
    const { oidcUser } = useOidcUser();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = useNotificationsData();

    return (
        <>
            <PageHelmet />
            <Link to={routes.HOME}> Home </Link> &gt; Notifications
            <div id="qg-two-col-aside" className="mt-4 tw-flex tw-flex-wrap tw-justify-center">
                {oidcUser ? (
                    <div className="col-md-9 col-sm-10 xs:tw-pl-3 xs:tw-pr-3">
                        <h1>Notifications</h1>
                        <table className="tw-min-w-full tw-shadow-md tw-rounded">
                            <thead>
                                <tr>
                                    <th className="tw-p-4 tw-font-bold tw-border-b-2 tw-border-black">Article</th>
                                    <th className="tw-p-4 tw-font-bold tw-border-b-2 tw-border-black">Published</th>
                                </tr>
                            </thead>
                            <tbody className="tw-divide-y tw-divide-gray-300">
                                {data?.notifications !== null ? (
                                    data?.notifications.map((c) => {
                                        return (
                                            <tr key={c.id} className="even:tw-bg-gray-50">
                                                <td className="tw-p-4">
                                                    <Link to={c.id}>{c.title}</Link>
                                                </td>
                                                <td className="tw-p-4">{formatAsDefaultDate(c.publishedOn)}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td>No notifications exist</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export { NotificationPage };
