import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'pages/routes';

const Signout = (): JSX.Element => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(routes.HOME);
    });

    return <div className="tw-m-10">Signing your account out...</div>;
};

export { Signout };
