import { useAppStore } from '@stores/appStore';
import type { OidcConfiguration } from '@axa-fr/react-oidc';
import { addTrailingSlashIfMissing } from '@utils/urlHelper';
import { useEffect, useMemo } from 'react';
import { useLoadConfigQuery } from '@services/AppConfig';
import { appEnvironment } from '@components/appEnvironment';
import { FeatureFlags } from 'flagged';

export type AppFeature = FeatureFlags;

export type AppConfig = {
    isReady: boolean;
    isLoading: boolean;
    oidcConfig?: OidcConfiguration;
    portalRootUrl: string;
    vcdPortalRootUrl: string;
    identityRootUrl: string;
    apiRootUrl: string;
    environment: appEnvironment;
    version: string;
    features?: AppFeature;
};

const defaultConfig: AppConfig = {
    isReady: false,
    isLoading: true,
    portalRootUrl: '',
    vcdPortalRootUrl: '',
    identityRootUrl: '',
    apiRootUrl: '',
    environment: appEnvironment.PROD,
    version: '',
};

const useGetConfig = (): AppConfig => {
    const { data: configData, isLoading, isError } = useLoadConfigQuery();
    const setConfig = useAppStore((state) => state.setConfig);
    const config = useAppStore((state) => state.config);

    useEffect(() => {
        if (!isLoading && configData && !isError) {
            setConfig(configData);
        }
    }, [configData, isLoading, isError, setConfig]);

    return useMemo(() => {
        if (isLoading || !config?.portalRootUrl) {
            return { ...defaultConfig, isLoading };
        }

        const appEnv = (config.environment as keyof typeof appEnvironment) || appEnvironment.PROD;
        const portalRootUrl = addTrailingSlashIfMissing(config.portalRootUrl ?? '');
        const vcdPortalRootUrl = addTrailingSlashIfMissing(config.vcdPortalRootUrl ?? '');
        const apiRootUrl = `${portalRootUrl}api/`;
        const identityRootUrl = config.identityRootUrl ?? '';

        const oidcConfig: OidcConfiguration = {
            client_id: 'spaclient',
            redirect_uri: `${portalRootUrl}signin-callback`,
            scope: 'openid profile identity.api regservices.api profileextended',
            authority: identityRootUrl,
            silent_redirect_uri: `${portalRootUrl}silent-renew`,
            service_worker_relative_url: '/OidcServiceWorker.js',
            service_worker_convert_all_requests_to_cors: true,
            service_worker_only: false,
            extras: {
                acr_values: 'idp:QGovOpenIdConnect',
            },
        };

        return {
            isReady: true,
            isLoading: false,
            oidcConfig,
            portalRootUrl,
            vcdPortalRootUrl,
            identityRootUrl,
            apiRootUrl,
            version: config.version ?? '',
            environment: appEnvironment[appEnv],
            features: config.features,
        };
    }, [config, isLoading]);
};

export { useGetConfig };
