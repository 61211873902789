import { AppFooter } from '@components/AppFooter/AppFooter';
import { SiteHeader } from '@components/SiteHeader/SiteHeader';

const Authenticating = (): JSX.Element => {
    return (
        <div className="container-fluid d-flex flex-column min-vh-100">
            <SiteHeader withAuth={false} />
            <main role="main">
                <div id="qg-content">
                    <div className="oidc-callback tw-mt-5">
                        <div className="oidc-callback__container">
                            <h1 className="oidc-callback__title">Authenticating in progress</h1>
                            <p className="oidc-callback__content">Redirecting to QLD Digital identity...</p>
                        </div>
                    </div>
                </div>
            </main>
            <AppFooter />
        </div>
    );
};

export { Authenticating };
