import { useOidcUser } from '@axa-fr/react-oidc';
import { useAuthAxios } from '@services/RegistrationRepository/useAuthAxios';
import { useQuery, UseQueryResult } from 'react-query';

export interface UserInfo {
    organisations: string[];
}

const useUserInfoQuery = (): UseQueryResult<UserInfo> => {
    const { axiosInstance: axiosClient } = useAuthAxios();
    const { oidcUser } = useOidcUser();
    return useQuery<UserInfo>(
        ['UserInfo', oidcUser],
        async (): Promise<UserInfo> => {
            const res = await axiosClient.get<UserInfo>(`/api/user`);
            return res.data;
        },
        { enabled: oidcUser !== undefined && oidcUser !== null },
    );
};

export { useUserInfoQuery };
