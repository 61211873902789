import { SiteHeader } from '@components/SiteHeader/SiteHeader';
import { AppFooter } from '@components/AppFooter/AppFooter';
import { BrowserRouter as Router } from 'react-router-dom';
import { useOidcUser } from '@axa-fr/react-oidc';

const Callback = (): JSX.Element => (
    <div className="oidc-callback tw-mt-5">
        <div className="oidc-callback__container">
            <h1 className="oidc-callback__title">Authentication Complete</h1>
            <p className="oidc-callback__content">Redirecting you back to the main application...</p>
        </div>
    </div>
);

const WrappedCallback = (): JSX.Element => {
    const { oidcUser, oidcUserLoadingState } = useOidcUser();
    console.debug(oidcUser, oidcUserLoadingState); //still undefined at this point. :-(
    return (
        <Router>
            <div className="container-fluid d-flex flex-column min-vh-100">
                <SiteHeader />
                <main role="main">
                    <div id="qg-content">
                        <Callback />
                    </div>
                </main>
                <AppFooter />
            </div>
        </Router>
    );
};

export default WrappedCallback;
