import { routes } from 'pages/routes';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import step1 from './assets/01-word-pdf.png';
import step2 from './assets/02-word-pdf.png';
import step3 from './assets/03-word-pdf.png';
import step4 from './assets/04-word-pdf.png';

const PageHelmet = (): JSX.Element => {
    return (
        <Helmet>
            <title>Regulatory self-service centre / About PDF Documents</title>
            <meta name="description" content="Regulatory self-service centre." />
            <meta name="keywords" content="Regulatory self-service centre" />
            <meta itemProp="description" content="Regulatory self-service centre" />
        </Helmet>
    );
};

const AboutPdf = (): JSX.Element => {
    return (
        <>
            <PageHelmet />
            <Link to={routes.HOME}> Home </Link> &gt; <Link to={routes.DOCUMENTS}>Document Uploads</Link> &gt; About PDF
            documents
            <div id="qg-two-col-aside" className="mt-4 tw-flex tw-flex-wrap tw-justify-center">
                <div className="col-md-8 col-sm-10">
                    <h2>About PDF documents </h2>
                    <p>
                        When uploading a Village Comparison Document, the preferred format we request is a PDF that is
                        PDF/A compliant. There are multiple versions of conformance, at a minimum aiming for level
                        PDF/A-1a is recommended.
                    </p>
                    <h2>What does &quot;PDF/A compliant&quot; mean?</h2>
                    <p>
                        PDF/A is a version of the Portable Document Format (PDF) that will ensure your document is able
                        to be reproduced exactly the same way, regardless of what software is used to open it. With
                        PDF/A, all the information necessary for exactly replicating your document is embedded in the
                        file, meaning your documents will be safe, accessible, and secure for the long term.
                    </p>
                    PDF/A compliant means your file meets the requirements of the PDF/A format. The most basic PDF/A
                    requirements are as follows:
                    <ul>
                        <li>
                            All content is embedded (fonts, colors, text, images, etc.) and does not contain audio/video
                        </li>
                        <li>The file is not encrypted</li>
                        <li>The file follows standards-based metadata</li>
                        <li>Form fields that are interactive must have an appearance dictionary</li>
                        <li>The file does not contain JavaScript</li>
                        <li>The file does not contain references to external content</li>
                        <li>The file does not contain XFA forms</li>
                    </ul>
                    <h2>Instructions if using Microsoft Office 365</h2>
                    If you are using a desktop version of Microsoft Word (part of Office 365), below are some tips on
                    ensuring the PDF document created conforms to PDF/a
                    <ul>
                        <li className="tw-mt-5">
                            <p>
                                Using the File menu, choose <strong>Save As</strong>
                                <br />
                            </p>
                            <img src={step1} alt="Using word to convert to PDF" height="250" />
                        </li>
                        <li className="tw-mt-5">
                            <p>
                                Set the file type to <strong>PDF (*.pdf)</strong>
                                <br />
                                Optimize for <strong>Minimum Size</strong>
                                <br />
                            </p>
                            <img src={step2} alt="Using word to convert to PDF" height="200" />
                        </li>
                        <li className="tw-mt-5">
                            <p>
                                Click the <strong>Options</strong> button
                                <br />
                            </p>
                            <img src={step3} alt="Using word to convert to PDF" height={100} />
                        </li>
                        <li className="tw-mt-5">
                            <p>
                                In the Options, select <strong>Document structure tags for accessibility</strong>
                                <br />
                                Under PDF Options, check <strong>PDF/A compliant</strong>
                                <br />
                            </p>
                            <img src={step4} alt="Using word to convert to PDF" height={400} />
                        </li>
                        <li className="tw-mt-5">
                            Finish by saving the document as a PDF. The PDF can now be uploaded to the portal.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export { AboutPdf };
