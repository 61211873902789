const SessionExpired = (): JSX.Element => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div className="oidc-callback tw-mt-5 tw-ml-5">
            <div className="oidc-callback__container">
                <h1 className="oidc-callback__title">Session Expired</h1>
                <p className="oidc-callback__content">
                    {' '}
                    Your session has expired. You will need to login again. Or try a{' '}
                    <button onClick={refreshPage}> page refresh </button>
                </p>
            </div>
        </div>
    );
};

export { SessionExpired };
