import { createWithEqualityFn } from 'zustand/traditional';
import { OrgStoreModel, Organisation } from './types/OrgModel';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Registration } from './types/Registration';
import { shallow } from 'zustand/shallow';

// Define the Zustand store for organization data
const useOrgStore = createWithEqualityFn<OrgStoreModel>()(
    persist(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (set, _get) => ({
            organisations: [] as Organisation[],
            orgDataLoaded: undefined,
            selectedOrganisation: undefined,
            selectedRegistration: undefined,
            isOrgDataLoading: false,

            // Set the loading state for organization data
            setIsOrgDataLoading: (val: boolean): void => set((state) => ({ ...state, isOrgDataLoading: val })),

            // Mark the organization data as loaded
            setOrgDataLoaded: (): void => set((state) => ({ ...state, orgDataLoaded: Date.now() })),

            // Add a new organization to the state
            addOrganisation: (organisation: Organisation): void =>
                set((state) => ({ ...state, organisations: [...state.organisations, organisation] })),

            // Clear all organizations from the state
            clearOrganisations: (): void => set((state) => ({ ...state, organisations: [] })),

            // Set the selected organization
            setSelectedOrganisation: (organisation: Organisation): void =>
                set((state) => ({ ...state, selectedOrganisation: organisation })),

            // Set the list of organizations
            setOrganisations: (organisations: Organisation[]): void => {
                if (organisations === null || organisations === undefined) {
                    set((state) => ({ ...state, organisations: [] }));
                } else {
                    set((state) => ({ ...state, organisations: organisations }));
                }
            },
            // Set the selected registration
            setSelectedRegistration: (registration: Registration): void =>
                set((state) => ({ ...state, selectedRegistration: registration })),

            // Clear the state
            clear: (): void =>
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                set((_state) => ({
                    organisations: [],
                    selectedOrganisation: undefined,
                    selectedRegistration: undefined,
                })),
        }),
        {
            name: 'organisation-store',
            storage: createJSONStorage(() => window.localStorage),
        },
    ),
    shallow,
);

export { useOrgStore };
