import { useNavigate } from 'react-router-dom';

export type HomeCardProps = {
    imageUrl: string;
    route?: string;
    link?: string;
    localLink?: string;
    title: string;
    description: string;
};

const HomeCard = (props: HomeCardProps): JSX.Element => {
    const navigate = useNavigate();

    const navigateTo = (route: string): void => {
        navigate(route);
    };

    const navigationHandler = (route?: string, link?: string, localLink?: string): void => {
        if (route) {
            navigateTo(route);
        } else if (link) {
            window.open(link, '_blank');
        } else if (localLink) {
            window.location.href = localLink;
        } else {
            console.warn('Error');
        }
    };

    const { imageUrl, route, title, description, link, localLink } = props;

    return (
        <div
            className="tw-h-full tw-max-w-md tw-overflow-hidden tw-shadow-lg tw-border-yellow-600 tw-border-t-4 tw-border-solid tw-border-b-0 tw-border-l-0 tw-border-r-0 hover:tw-shadow-lg tw-cursor-pointer hover:tw-bg-gray-100 hover:tw-underline"
            onClick={() => navigationHandler(route, link, localLink)}
        >
            <div className="tw-flex tw-justify-center tw-mt-4">
                <img src={imageUrl} width={120} height={120} alt="" style={{ height: '120px' }} />
            </div>
            <div className="tw-px-6 tw-py-4">
                <h2 className="tw-font-bold tw-text-xl tw-mb-2">{title}</h2>
                <p className="tw-leading-relaxed tw-text-base">{description}</p>
            </div>
        </div>
    );
};

export { HomeCard };
