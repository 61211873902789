import { useNotificationsData } from 'hooks/useNotificationsData';
import { routes } from 'pages/routes';
import { Helmet } from 'react-helmet-async';
import 'react-popper-tooltip/dist/styles.css';
import { Link, useParams } from 'react-router-dom';
import createDOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { Notification } from 'hooks/Notification';
import { formatAsDefaultDate } from '@utils/dateFormatter';

const PageHelmet = (): JSX.Element => {
    return (
        <Helmet>
            <title>Regulatory self-service centre</title>
            <meta name="description" content="Regulatory self-service centre." />
            <meta name="keywords" content="Regulatory self-service centre" />
            <meta itemProp="description" content="Regulatory self-service centre" />
        </Helmet>
    );
};

const NotificationView = (): JSX.Element => {
    const { isLoading, data } = useNotificationsData();
    const DOMPurify = createDOMPurify(window);
    const [notification, setNotification] = useState<Notification>();
    const { id } = useParams<'id'>();

    useEffect(() => {
        if (isLoading) {
            return;
        }
        const found = data?.notifications.find((c) => c.id === id);
        setNotification(found);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isLoading]);

    return (
        <>
            <PageHelmet />
            <div>
                <Link to={routes.HOME}> Home </Link> &gt; <Link to={routes.NOTIFICATIONS}> Notifications </Link> &gt;
                View
                <div id="qg-two-col-aside" className="mt-4 tw-flex tw-flex-wrap tw-justify-center">
                    <div className="col-md-8 col-sm-10 xs:tw-pl-3 xs:tw-pr-3 tw-mb-8">
                        {notification ? (
                            <>
                                <h1>{notification.title}</h1>
                                <p className="tw-mb-8">{formatAsDefaultDate(notification.publishedOn)}</p>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(notification.bodyHTML, {
                                            USE_PROFILES: { html: true },
                                            ADD_ATTR: ['target'],
                                        }),
                                    }}
                                ></div>
                            </>
                        ) : null}
                        <hr />
                        <Link to={routes.NOTIFICATIONS}> Go back to Notifications</Link>
                    </div>
                </div>
            </div>
        </>
    );
};
export { NotificationView };
