import { Navigate, Route, Routes } from 'react-router-dom';
import { OidcSecure } from '@axa-fr/react-oidc';
import { routes } from '@pages/routes';
import { HomePage } from '@pages/Home';
import { Signout } from '@pages/Signout';
import { Logout } from '@pages/Logout';
import { NotFound } from '@pages/NotFound';
import { AboutPdf } from '@pages/AboutPdf';
import { NotificationPage } from '@pages/Notifications';
import { NotificationView } from '@pages/Notifications/NotificationView';
import { lazy, Suspense } from 'react';

const ManageUsers = lazy(() => import('./pages/ManageUsers/'));
const DocumentsPage = lazy(() => import('./pages/Documents'));
const EditUser = lazy(() => import('./pages/EditUser'));

function Loading(): JSX.Element {
    return <div>Loading...</div>;
}

const AppRoutes = (): JSX.Element => {
    return (
        <Routes>
            <Route path={routes.HOME} element={<HomePage />} />
            <Route path={routes.SIGNOUT_CALLBACK} element={<Navigate to={routes.HOME} />} />
            <Route path={routes.ABOUT_PDF} element={<AboutPdf />} />
            <Route
                path={routes.DOCUMENTS}
                element={
                    <OidcSecure>
                        <Suspense fallback={<Loading />}>
                            <DocumentsPage />
                        </Suspense>
                    </OidcSecure>
                }
            />

            <Route
                path={routes.MANAGER_USER}
                element={
                    <OidcSecure>
                        <Suspense fallback={<Loading />}>
                            <EditUser />
                        </Suspense>
                    </OidcSecure>
                }
            />
            <Route
                path={routes.MANAGE_USERS}
                element={
                    <OidcSecure>
                        <Suspense fallback={<Loading />}>
                            <ManageUsers />
                        </Suspense>
                    </OidcSecure>
                }
            />
            <Route
                path={routes.NOTIFICATIONS}
                element={
                    <Suspense fallback={<Loading />}>
                        <NotificationPage />
                    </Suspense>
                }
            />
            <Route
                path={routes.NOTIFICATION_VIEW}
                element={
                    <Suspense fallback={<Loading />}>
                        <NotificationView />
                    </Suspense>
                }
            />
            <Route path={routes.SIGNOUT} element={<Signout />} />
            <Route path={routes.LOGOUT} element={<Logout />} />
            <Route path={''} element={<HomePage />} />
            <Route element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
