import { generatePath } from 'react-router-dom';

const MANAGE_USERS = '/users/manage';
const MANAGER_USER = '/users/manage/user/:id';
const DISCLAIMER = '/disclaimer';
const SEARCH = '/search';
const ABOUT = '/about';
const CONTRACT_ENTITLEMENTS = '/contracts-entitlements';
const HOME = '/';
const FEEDBACK_THANKYOU = '/feedback/thank-you';
const FINANCIAL_STATEMENTS = '/financial-statements';
const DOCUMENTS = '/documents';
const OIDC_CALLBACK = '/auth/OidcCallback';
const LOGOUT = '/logout';
const NOTIFICATIONS = '/notifications';
const SIGNOUT = '/auth/signout';
const SIGNOUT_CALLBACK = '/auth/signout-callback-oidc';
const ABOUT_PDF = '/village-comparison-document/about-pdf';
const NOTIFICATION_VIEW = '/notifications/:id';

const manageUser = (id: string): string => generatePath(MANAGER_USER, { id: id });

const routeBuilder = () => {
    return routes;
};

const routes = {
    DISCLAIMER,
    SEARCH,
    ABOUT,
    CONTRACT_ENTITLEMENTS,
    FINANCIAL_STATEMENTS,
    HOME,
    MANAGER_USER,
    NOTIFICATIONS,
    NOTIFICATION_VIEW,
    manageUser,
    FEEDBACK_THANKYOU,
    MANAGE_USERS,
    OIDC_CALLBACK,
    DOCUMENTS,
    LOGOUT,
    ABOUT_PDF,
    SIGNOUT,
    SIGNOUT_CALLBACK,
};

export { routes, routeBuilder };
