import { useOidcUser } from '@axa-fr/react-oidc';
import { SignInCard } from '@features/homepage';
import { ExternalLinks } from '@pages/externalLinks';
import { ExternalLink } from '@components/ExternalLink/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { OrganisationContainer } from '@components/OrganisationContainer/OrganisationContainer';
import { useEffect, useState } from 'react';
import { UserActionCards } from '@features/homepage/components/UserActionCards';
import { PageHelmet } from './components/PageHelmet';
import { useUserInfoQuery } from './hooks/useUserInfoQuery';

const NoOrganisationPermission = (): JSX.Element => {
    return (
        <div className="tw-flex tw-mt-10 tw-place-content-center tw-gap-10">
            <div className="alert alert-warning tw-flex tw-flex-row tw-gap-4 md:tw-w-1/2" role="alert">
                <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} color="orange" />
                <div>
                    <p>
                        Your account has not been linked to an organisation.
                        <br className="tw-mb-2" />
                    </p>
                    <ul className="tw-pl-4">
                        <li>
                            If you know of the corporate representative designated for the organisation you are
                            attempting to access, they will have the capability to provide access and should be
                            contacted for support.
                        </li>
                        <li>
                            Alternatively, contact{' '}
                            <ExternalLink href={ExternalLinks.ContactUs}>
                                Regulatory Services, Department of Housing
                            </ExternalLink>{' '}
                            for further assistance.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const HomePage = (): JSX.Element => {
    return (
        <>
            <PageHelmet />
            <div id="qg-two-col-aside">
                <HomePageContent />
            </div>
        </>
    );
};

const HomePageContent = (): JSX.Element | null => {
    const { oidcUser } = useOidcUser();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isLoading: isLoadingUserInfo, data: userInfo } = useUserInfoQuery();

    // hack to avoid unsightly display of signin content while userinfo hasn't loaded.
    useEffect(() => {
        if (!isLoadingUserInfo) {
            setTimeout(() => {
                setIsLoading(false);
            }, 100);
        }
    }, [isLoadingUserInfo]);

    const showSelfServiceFeatures = !isLoadingUserInfo && userInfo !== undefined && userInfo.organisations?.length > 0;
    const showNoAccess = !isLoadingUserInfo && userInfo !== undefined && userInfo.organisations?.length === 0;

    if (isLoading) {
        return null;
    }

    if (oidcUser) {
        return (
            <>
                <div className="mt-4 tw-flex tw-flex-wrap tw-justify-center">
                    <div className="tw-flex tw-justify-center grid">
                        <div
                            className="lg:tw-w-8/12 md:tw-w-4/4 sm:tw-w-full tw-shadow-sm tw-p-6 tw-border-red-700 tw-border-t-0 tw-border-solid tw-border-b-0 tw-border-l-0 tw-border-r-0
                            tw-bg-gray-50"
                        >
                            <h1>Welcome</h1>
                            <p>
                                Use the self-service centre to manage your organisation&apos;s documents and users, and
                                help meet your obligations under the{' '}
                                <em>
                                    <ExternalLink href="https://www.legislation.qld.gov.au/view/html/inforce/current/act-1999-071">
                                        Retirement Villages Act 1999
                                    </ExternalLink>
                                </em>
                            </p>
                            <OrganisationContainer />
                        </div>
                    </div>
                </div>

                {showSelfServiceFeatures && <UserActionCards></UserActionCards>}
                {showNoAccess && <NoOrganisationPermission></NoOrganisationPermission>}
            </>
        );
    }

    return (
        <>
            <SignInCard />
            <div className="tw-my-6 tw-flex tw-items-center tw-justify-center ">
                <div className="xl:tw-w-6/12 lg:tw-pl-16 lg:tw-pr-16 lg:tw-w-8/12 md:tw-w-8/12">
                    <p>
                        Select <strong>Go to Qld Digital Identity</strong> to log in.
                    </p>
                    <p>
                        If you don&apos;t already have a Qld Digital Identity, you&apos;ll need to create one to log in
                        to the Regulatory self-service centre.
                    </p>
                </div>
            </div>
        </>
    );
};

export { HomePage };
