import { AppConfig } from '@stores/types/appConfig';
import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

export const useLoadConfigQuery = (): UseQueryResult<AppConfig> => {
    return useQuery<AppConfig>(['loadConfigQuery'], async (): Promise<AppConfig> => {
        const res = await axios.get<AppConfig>(`/api/config`);
        //console.log('getConfig data',res.data);
        return res.data;
    });
};
