import { appEnvironment } from '.././appEnvironment';

export type EnvironmentHeaderProps = {
    environment: appEnvironment;
    text?: string;
};

const environmentStyles: Record<appEnvironment, React.CSSProperties> = {
    [appEnvironment.DEV]: {
        background: 'linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)',
    },
    [appEnvironment.UAT]: {
        background: 'radial-gradient(circle, rgba(0,126,177,1) 5%, rgba(0,171,178,1) 50%)',
    },
    [appEnvironment.PROD]: {
        background: 'linear-gradient(185deg, rgba(0,126,177,1) 35%, rgba(0,171,178,1) 100%)',
    },
};

export const EnvironmentHeader: React.FC<EnvironmentHeaderProps> = ({ environment, text }) => {
    if (environment === appEnvironment.PROD) {
        return null;
    }

    const style: React.CSSProperties = {
        height: '30px',
        color: 'white',
        ...environmentStyles[environment],
    };

    return (
        <div aria-hidden="true" className="d-flex flex-row justify-content-center d-print-none" style={style}>
            <span style={{ fontSize: '15pt', fontWeight: 'bold' }}>
                {environment}
                {text && (
                    <span style={{ fontSize: 'small' }} className="ml-2">
                        {text}
                    </span>
                )}
            </span>
        </div>
    );
};
