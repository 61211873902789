import { useOrgStore } from '@stores/OrgStore';
import { ChangeEvent, useEffect } from 'react';
import { Organisation } from '@stores/types/OrgModel';
import { shallow } from 'zustand/shallow';

export type OrganisationSelectorProps = {
    organisations: Organisation[];
};

export const OrganisationSelector = (props: OrganisationSelectorProps): JSX.Element | null => {
    const { organisations } = props;
    const { setSelectedOrganisation, selectedOrganisation, storeOrganisations, setSelectedRegistration } = useOrgStore(
        (state) => ({
            setSelectedOrganisation: state.setSelectedOrganisation,
            selectedOrganisation: state.selectedOrganisation,
            storeOrganisations: state.organisations,
            setSelectedRegistration: state.setSelectedRegistration,
        }),
        shallow,
    );

    const handleOrgChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        setSelected(+selectedId);
    };

    const setSelected = (orgId: number) => {
        console.debug('setSelectedOrg:', orgId);
        const selOrg = storeOrganisations.find((org) => org.id === orgId);
        setSelectedOrganisation(selOrg);
        setSelectedRegistration(undefined);
    };

    useEffect(() => {
        if (!organisations || organisations.length === 0) return;
        if (organisations.length === 1) {
            const orgId = organisations[0].id;
            console.debug('Single Organisation with id', orgId);
            setSelected(orgId);
        } else if (!selectedOrganisation && organisations.length > 0) {
            //set default
            const initialOrgId = organisations[0].id;
            console.debug('OrganisationSelector: Set initial organisation to', initialOrgId);
            setSelected(initialOrgId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations]);

    if (organisations.length === 0) return null;

    return (
        <select
            id="organisation"
            title="organisation"
            className="form-control"
            value={selectedOrganisation?.id}
            onChange={handleOrgChange}
        >
            {organisations.map((org) => (
                <option key={org.id} value={org.id}>
                    {org.name}
                </option>
            ))}
        </select>
    );
};
